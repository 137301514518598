import refs from "./refs"
import {handleQuantity} from "../shop/productQuantity"

const {
    cartModal,
    checkoutOrderWrapper,
    cartWrapper,
    checkoutWrapper,
    cartCountEl,
    formGroupInner,
    summaryWrapper
} = refs
const {ajax_url, shop_page_url} = settings

let debounce
let isProcessing = false

function onQuantityClick(e) {
    if (isProcessing) return

    clearTimeout(debounce)

    const $this = $(e.currentTarget)
    const productItem = $this.closest(".cart-products__item")
    const {productId} = productItem.data() || {}
    const quantity = handleQuantity(e)

    debounce = setTimeout(() => updateQuantity(productId, quantity), 300)
}

function updateQuantity(productId, quantity) {
    isProcessing = true

    cartModal.addClass("loading")
    checkoutOrderWrapper.addClass("loading")

    const query = {
        productId,
        quantity,
    }

    $.ajax({
        url: ajax_url,
        type: 'post',
        data: {action: 'update_product_quantity', ...query},
        success: ({data, success}) => {
            if (!success) return
            isProcessing = false

            updateUI(data)

            cartModal.removeClass("loading")
            checkoutOrderWrapper.removeClass("loading")
        },
        error: (error) => console.log("error: ", error),
    })
}

function handleRemove(e) {
    const $this = $(e.currentTarget)

    const productItem = $this.closest(".cart-products__item")
    const {productId} = productItem.data()

    removeProduct(productId)
}

function removeProduct(productId) {
    cartModal.addClass("loading")
    checkoutOrderWrapper.addClass("loading")

    const query = {productId}

    $.ajax({
        url: ajax_url,
        type: 'post',
        data: {action: 'remove_product', ...query},
        success: ({data, success}) => {
            if (!success) return

            if (!data.cartCount && window.location.pathname.includes("checkout")) {
                window.location.href = shop_page_url

                return;
            }

            data.weightExists ? formGroupInner.addClass("weight_exists") : formGroupInner.removeClass("weight_exists")

            updateUI(data)
            cartModal.removeClass("loading")
            checkoutOrderWrapper.removeClass("loading")
        },
        error: (error) => console.log("error: ", error),
    })
}

function updateUI({cartMarkup, cartProducts, cartCount, summary}) {
    cartWrapper.html(cartMarkup)
    checkoutWrapper.html(cartProducts)
    summaryWrapper.html(summary)
    updateCartCount(cartCount)
}

function updateCartCount(count) {
    count ? cartCountEl.removeClass("hidden") : cartCountEl.addClass("hidden")

    cartCountEl.text(count)
}

cartWrapper.on("click", ".quantity__button", onQuantityClick)
checkoutWrapper.on("click", ".quantity__button", onQuantityClick)
cartWrapper.on("click", ".delete-button", handleRemove)
checkoutWrapper.on("click", ".delete-button", handleRemove)

$(document.body).on('added_to_cart', (_, data) => updateUI(data));