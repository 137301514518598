const productsItems = $('.products-items')

export const handleQuantity = (e, updateBuyButton) => {
    const $this = $(e.currentTarget);
    let quantityWrapper = $this.closest('.quantity');
    quantityWrapper = quantityWrapper.length ? quantityWrapper : $this.closest('.quantity-container');

    const quantityEl = quantityWrapper.find('.quantity__value');
    const quantityText = quantityEl.text().trim();
    const step = quantityWrapper.data("step") || 1;

    const delta = $this.hasClass('increment') ? step : -step;

    const [quantityValue, measureUnit = ""] = quantityText.split(" ");

    const currentQuantity = Number(quantityValue);
    const newQuantity = Math.max(step, parseFloat((currentQuantity + delta).toFixed(1)));
    const normalizedQuantity = (newQuantity / step).toFixed(0)

    quantityEl.text(`${newQuantity} ${measureUnit}`);

    if(updateBuyButton){
        const closestProductCart = $this.closest('.product')
        const buyButton =  closestProductCart.length ? closestProductCart.find('.add_to_cart_button') : $(".add-to-cart-js");

        buyButton.attr('data-quantity', normalizedQuantity);
    }

    return normalizedQuantity
};

productsItems.on("click", ".quantity__button", (e) => handleQuantity(e, true));
